.orangeCard {
    background-color: #ff7c21;
    color: #fff;
}

.orangeCard h3 {
    color: #fff;
    margin: 0;
}

.orangeCard:hover {
    background-color: #ff7c21;
    // color:#ff7c21;
}

.orangeCard:hover h3,
.orangeCard:hover span {
    // background-color: #fff;
    color: #fff !important;
}

.orangeCard {

    .dash-widget-icon {
        background: transparent;
        color: white;
        font-size: 50px;
        border-radius: unset;
        border-right: 1px solid;
        height: 60px;
        padding-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dash-widget-info {
        text-align: start;
    }
}

.chartrow {
    .card {
        .card-body {
            height: 500px;

            &.chart1 {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}

.card_container {
    .project_mini_card {
        border-radius: 20px;
        border: 1px solid #E9E9E9;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;

        table {
            width: 100%;
            margin: 1rem;

            td {

                &.title {
                    color: #60514E;
                    font-family: "CircularStd";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                }

                &.content {
                    color: #FF7C21;
                    font-family: "CircularStd";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    max-width: 100px;
                    overflow: hidden;
                }
            }
        }
    }
}