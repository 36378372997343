:root {
    --bs-btn-active-color: white;
}

* {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, height, width !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 0.3s !important;
}

.text-success {
    color: var(--primary);
}

.text-primary {
    color: var(--primary);
}

.text-error {
    color: var(--error);
}

.bg-primary {
    background-color: var(--primary);
}

.hover\:bg-primary:hover {
    background-color: var(--primary);
}

.step.activated {
    background-color: var(--primary);
    color: white;
}

.left-attach-border {
    height: calc(100% + 15px);
    display: inline-block;
    border-left: 1px solid gray;
    padding: 0px 4px;
    margin: -15px 0px;
}

@media (min-width: 576px) {
    // .modal-dialog {
    //     max-width: calc(100vw - 50px);
    // }
}

.grid-fit {
    grid-template-columns: repeat(auto-fit, minmax(min-width, 0));
}

.grid-auto {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.grid-auto-300px {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
}

.grid-auto-100px {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr))
}

.grid-auto-50px {
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr))
}

form.form {
    padding: 2rem;
    background-color: white;
}

.btn {
    background-position: center;
}

.btn.bg-primary:hover,
.btn.btn-success:hover,
.btn.add-btn:hover {
    background-color: var(--secoundary);
    background: var(--secoundary) radial-gradient(circle, transparent 1%, var(--secoundary) 1%) center/15000%;
}

.btn.bg-primary:active,
.btn.btn-success:active,
.btn.add-btn:active {
    background-color: var(--primary);
    background-size: 100%;
}

.accordion {
    .collapse {
        visibility: initial;
    }

    .accordion-button:not(.collapsed) {
        background: white;
        color: black;
    }

    .accordion-button::after {
        width: 50px;
        height: 50px;
        background-size: 2.25rem;
    }

    .accordion-button:focus {
        border: none;
        box-shadow: none;
    }

    .accordion-button::after {
        background-image: url("../assets/img/arrow_right.svg");
        background-position: center;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("../assets/img/arrow_up.svg");
        transform: rotate(0deg);

    }

    .accordion-button:not(.collapsed) {
        .button_content .title {
            color: var(--primary);
        }
    }

    .button_content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .status_box {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            span {
                margin-left: 1.5rem;

                i {
                    font-size: 30px;
                    margin: 0;
                    margin-right: 10px;

                    &.active {
                        color: #36D789;
                    }

                    &.disabled {
                        color: #FBA106;
                    }

                }
            }

            .status_text {
                text-transform: capitalize;
                line-height: 1.8;
            }

            .ant-switch {
                width: 33px;

                &:focus {
                    box-shadow: none;
                }
            }

            .ant-switch-checked {
                background-color: var(--primary);

            }
        }

        .crud_box {
            border-left: 2px solid #E6E6E6;
            margin-left: 1rem;
            padding-left: 1rem;

            button {
                border: 0;
                background-color: transparent;
                font-size: 22px;

                i {
                    color: #0000004D;
                }
            }
        }
    }

    .accordion-body {
        color: #909090;
        font-family: "CircularStd";
        font-size: 16px;
        font-style: normal;
        font-weight: normal;

        .link_box_container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 20px 0;

            .link_box {
                margin: 0;
                margin-right: 1rem;
                margin-bottom: 1rem;
                width: 136px;
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                background: #F1F2F4;
                color: #020202;
                font-family: "CircularStd";
                font-size: 14px;
                font-style: normal;

                &:hover {
                    background-color: #e8e8e8;
                }
            }
        }
    }

}

.ant-table {
    min-width: fit-content;
    padding-bottom: 60px !important;

    table tr th.ant-table-selection-column,
    table tr td.ant-table-selection-column {
        padding-right: 0px !important;
    }
}

.ant-pagination.ant-table-pagination.ant-table-pagination-right {
    position: absolute;
    right: 12px;
    bottom: 0;
}

.appcard.card {
    background-color: #fafafa;
}

.appcard.card:hover {
    background-color: #fff;
}

.employee-profile .appcard.card {
    min-height: calc(100% - 30px);
}

.AppDateTimePicker.text-center>input {
    text-align: center;
}

.react-dropdown-select-dropdown {
    min-width: 100%;
    top: 100% !important;
    overflow-x: hidden !important;
}

.calender-outer {
    left: 50%;
    top: 50%;
    transform: translate(-50%, 10%);
}

.react-dropdown-select {
    width: 100% !important;
    height: 44px;
    border-radius: 4px !important;
    background: white;
    // padding: 21px 12px !important;
}

.react-dropdown-select-content.react-dropdown-select-type-multi {
    height: 35px !important;
    overflow: hidden;
}

.react-dropdown-select[disabled],
.ant-upload-disabled {
    opacity: 0.8;
    background-color: #e9ecef;

    & .ant-btn-default,
    & .ant-btn-default:hover {
        background-color: #e9ecef;
    }
}

.ant-upload-disabled {
    &+.ant-upload-list {
        pointer-events: none !important;
        cursor: not-allowed;
    }
}

.candidatetest {
    .ant-upload-disabled {
        &+.ant-upload-list {
            pointer-events: all !important;
            cursor: pointer !important;
        }
    }
}

.form-group .ant-btn-default {
    height: 44px;
}

@media (min-width: 768px) {
    .md\:grid-cols-3 {
        grid-template-columns: 1fr 1fr 1fr auto;
    }
}

// ROUNDED STEPPER TABS 

:root {
    --circle-size: clamp(1.75rem, 5vw, 3.5rem);
    --spacing: clamp(0.5rem, 2vw, 0.25rem);
}

.c-stepper {
    display: flex;
    counter-reset: my-sec-counter;

    .c-stepper__item {
        display: flex;
        flex-direction: column;
        flex: 1;
        text-align: center;

        &.c-stepper__item_active::before {
            background: var(--primary) !important;
            border: 2px solid var(--primary) !important;
            content: "\f00c";
            font-family: 'Font Awesome 5 Free', 'Font Awesome 5 Regular', 'Font Awesome 5 Brands', 'FontAwesome', 'Arial';
            font-weight: 900;
            color: white;
        }

        &:before {
            --size: 3rem;
            content: "";
            display: block;
            width: var(--circle-size);
            height: var(--circle-size);
            border-radius: 50%;
            border: 2px solid #D2D2D2;
            background-color: lightgrey;
            background-color: transparent;
            margin: 0 auto 1rem;
            margin-left: 0;
            counter-increment: my-sec-counter;
            content: counter(my-sec-counter);
            display: flex;
            align-items: center;
            justify-content: center;


            color: #404040;
            font-family: "CircularStd";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
        }

        &.c-stepper__item_progress:before {
            border: 2px solid var(--primary) !important;
        }

        &:not(:last-child) {
            &:after {
                content: "";
                position: relative;
                top: calc(var(--circle-size) / 2);
                width: calc(100% - var(--circle-size) - var(--spacing) * 2 - 15px);
                left: calc(var(--circle-size) + 15px);
                height: 2px;
                background-color: transparent;
                border-top: 2px solid #AFAFAF;
                border-top-style: dashed;
                order: -1;
            }
        }
    }

    .c-stepper__title {
        font-size: clamp(1rem, 4vw, 1.05rem);
        margin-bottom: 0.5rem;

        color: #6A6A6A;
        text-align: start;
        font-family: "CircularStd";
        font-style: normal;
        font-weight: 450;
    }

    .c-stepper__desc {
        color: grey;
        font-size: clamp(0.85rem, 2vw, 1rem);
        padding-left: var(--spacing);
        padding-right: var(--spacing);
    }
}

// App Video Section
.video-container {
    padding: 20px;
    background-color: #fff;

    .title {
        color: var(--primary);
        font-family: Circular Std;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        /* 68.182% */
    }

    .video-section {
        padding: 10px;
        margin: 20px 0;
        background-color: #404040;
        height: 312.862px;
    }

    .video-list-header {
        font-family: Circular Std;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        margin-top: 20px;
    }

    .video-list {
        margin-top: 20px;

        .list-video {
            background-color: #404040;
            height: 48px;
        }

        .video-list-head {
            color: #909090;
            font-family: Circular Std;
            font-size: 12px;
            font-style: normal;
            font-weight: 450;
            line-height: 20px;
            /* 166.667% */
        }
    }

    .percentage {
        background-color: var(--primary);
        border: none;
        color: #fff;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 16px;

    }

    .footer-section {
        margin-top: 20px;
    }
}