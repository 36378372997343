.media_box {
    min-height: 400px;
    padding: 24px 10px;
    box-shadow: none;
    border: none;

    .card-header,
    .card-body {
        border-bottom: none;
    }

    .card-footer {
        border-top: none
    }

    .card-header {
        .title {
            margin: 0;
            color: var(--primary);
            font-family: "CircularStd";
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
        }
    }

    .card-body {
        .placeholder_text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #171717;
            font-family: "CircularStd";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
        }

        .iframe_container {
            width: 100%;
            height: 100%;
            min-height: 450px;
            background-color: #bababa;
        }

        .content_description {
            color: #171717;
            font-family: "CircularStd";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
        }

        .mini_box_container {
            .mini_box {
                .title {
                    color: #909090;
                    font-family: "CircularStd";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 450;
                }

                .thumbnail_box {
                    width: 100%;
                    max-width: 100px;
                    height: 50px;
                    background-color: #bababa;
                    cursor: pointer;
                }

                label {
                    cursor: pointer;
                }
            }
        }
    }

    .card-footer {
        .min-wd-100 {
            min-width: 100px;
        }

        .social_btn {
            border: 0;
            background-color: transparent;
            margin: 0;
            margin-right: 4px;
            margin-left: 4px;
            color: #363636;
            font-family: "CircularStd";
            font-size: 16px;
            font-style: normal;

            i {
                margin: 0;
                margin-right: 8px;
            }
        }
    }
}

.form_outer {
    margin: 1rem;
    padding: 16px;
    border-radius: 8px;
    background: #FFF;
}

.custom_form {
    .option_box {
        padding: 10px 20px;
        margin: 0;
        margin-bottom: 8px;
        border-radius: 6px;
        border: 1px solid #D6D6D6;
        background: #FBFBFB;

        // .form-group {
        //     border-radius: 6px;
        //     border: 1px solid #D6D6D6;
        //     background: #FBFBFB;
        // }
        .col-form-label {
            font-size: 16px;
        }

        textarea.form-control {
            min-height: 100px;
            margin-bottom: 1rem;
        }

        .react-dropdown-select {
            margin-bottom: 1rem;
        }

        .outer {
            display: flex;
            flex-wrap: wrap;


            .list-group-item {
                border: none !important;
                background-color: transparent !important;

                label {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                }

            }
        }
    }
}