.addbudget {
    .empty_layout {
        background-image: url('../../../../assets/img/fy_empty_bg.png');
        background-repeat: no-repeat;
        background-position: center;
        background-color: white;
    }

    .budget_quater1_amount label,
    .budget_quater2_amount label,
    .budget_quater3_amount label {
        font-size: large;
    }

}