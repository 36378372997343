.cstmCard,
.cstmCard h3 {
    background-color: #FF7C21 !important;
    color: #fff !important;
}

// .custom-modal {
//     margin: 0;
//     // width: 30%;
//     display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
// }

.orangeButton {
    height: 38px;
    width: 135px;
    background-color: #F37721;
    color: #fff !important;
    border-radius: 88px;
    border: none;

}

.greyButton {
    height: 38px;
    width: 135px;
    background-color: #B7B7B7;
    color: #707070 !important;
    border-radius: 88px;
    border: none;
}