.reportsummary {
    // table {
    //     table-layout: auto !important;
    // }

    .ant-table-tbody>tr>td {
        width: 150px;
        white-space: normal;
    }

    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
        height: 100%;
    }

    .ant-table-thead>tr>th {
        background-color: #FFE8D9 !important;
        border-bottom: none !important;

        // &::before {
        //     height: 0 !important;
        // }

        &.q-col {
            background-color: #FFC7A0 !important;
            border-bottom: none !important;
        }
    }
}