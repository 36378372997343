.forum_top_select {
    &.small {
        .react-dropdown-select {
            color: grey !important;
            max-width: 180px !important;
            pointer-events: none;
        }
    }

    .react-dropdown-select {
        border-radius: 100px !important;
    }

    .react-dropdown-select-content {
        color: #938E8E;
        text-align: center;
        font-family: "CircularStd";
        font-style: normal;
        font-weight: 500;
        justify-content: end;
        font-size: 16px;
    }

    .react-dropdown-select-dropdown-handle {
        display: none;
    }
}

.forum_tabs_group {
    button {
        margin: 0 1rem;
        color: black;
        text-align: center;
        font-family: "Montserrat";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        border: 0;
        border-bottom: 2px solid transparent;
        background: transparent;
        line-height: 1.8;

        &.active {
            color: var(--primary);
            border-bottom: 2px solid var(--primary);
            transition: all 0.3s ease;
        }
    }
}

.forum_table_outer {
    .ant-table-tbody {
        tr {
            &.ant-table-row:hover>td {
                background: #FFF7F1 !important;
                cursor: pointer;

                p.title {
                    color: var(--primary);
                }
            }

            td {
                padding: 1.5rem 1rem !important;
            }
        }
    }
}

.forum_details_container {
    padding: 16px 8px;

    &:hover {
        background-color: white !important;
    }

    .card-header {
        border: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            color: #131313;
            font-family: "CircularStd";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 34px;
        }

        .timestamp {
            color: #A7A7A7;
            font-family: "CircularStd";
            font-size: 14px;
        }
    }

    .card-body {
        .detail_card {
            display: flex;
            margin: 0;
            margin-bottom: 1rem;

            img {
                margin: 0;
                margin-right: 0.5rem;
                border-radius: 100px;
                width: 30px;
            }

            p {
                &:first-child {
                    color: #A0A0A0;
                    font-family: "CircularStd";
                    font-size: 12px;
                    font-style: normal;
                }

                &.name {
                    color: var(--primary);
                    font-family: "CircularStd";
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }

        .content_box {
            p {
                color: #444 !important;
                font-family: "CircularStd";
                font-size: 14px;
            }
        }

        .action_box {
            button {
                border: 0;
                background: transparent;
                padding: 1rem;
                color: #9E9E9E;
                font-family: "CircularStd";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;

                i {
                    margin: 0;
                    margin-right: 8px;
                    font-size: 18px;

                    &.active {
                        color: var(--primary);
                    }
                }
            }
        }

        .comment_box_container {

            .comment_box_card {
                position: relative;
                // display: flex;
                margin: 0;
                margin-bottom: 1rem;
                cursor: pointer;

                &.reply_comment_box {

                    &::before {
                        content: "";
                        display: block;
                        width: 1px;
                        height: 100%;
                        background: #A0A0A0;
                        position: absolute;
                        top: -8px;
                        left: -10px;
                    }

                    .name_circle {
                        width: 23px;
                        height: 23px;
                    }

                    .content {
                        .title {
                            font-size: 14px;
                        }
                    }
                }

                .delete_btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #787575;
                    border: 0;
                    background: transparent;
                    font-size: 16px;
                    cursor: pointer;
                    border: 1px solid transparent;
                    border-radius: 100px;
                    transition: none;
                    transition-property: none !important;
                    transition-timing-function: none !important;
                    transition-duration: 0 !important;

                    &:hover {
                        border: 1px solid #787575;
                        background: #787575;
                        color: white !important;
                    }
                }

                .reply_btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: 0;
                    right: 4rem;
                    color: #787575;
                    border: 0;
                    background: transparent;
                    font-size: 16px;
                    cursor: pointer;
                    border: 1px solid transparent;
                    transition: none;
                    transition-property: none !important;
                    transition-timing-function: none !important;
                    transition-duration: 0 !important;

                    &:hover {
                        // border: 1px solid #787575;
                        color: #313030 !important;
                    }
                }

                .name_circle {
                    width: 33px;
                    height: 33px;
                    color: white;
                    font-weight: bold;
                    background-color: #692E20;
                    border-radius: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .content {
                    .title {
                        color: #131313;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                    }

                    p {
                        color: #444 !important;
                        font-family: "CircularStd";
                        font-size: 14px;
                    }
                }
            }

            .view_reply_btn {
                border: 0;
                background-color: transparent;
                color: var(--primary);
                margin: 0;
                margin-left: 2rem;
                font-size: 14px;
                font-weight: 600;
                text-decoration: underline;
            }

        }
    }

    .card-footer {
        padding-top: 35px;

        input {
            border-radius: 100px;
            border: 1px solid #ACACAC;
            background: #EEE;
            padding: 1.5rem;
        }

        button.submit_btn {
            border-radius: 100px;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}