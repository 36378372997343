.module_detail_container {
    min-height: 500px;
    padding: 30px;

    .title {
        color: #2C2C2C;
        font-family: "CircularStd";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        margin: 0;
    }

    .sub_title {
        color: #7C7C7C;
        font-family: "CircularStd";
        font-size: 18px;
        font-style: normal;
        font-weight: 450;
    }

    .media_card,
    .assessment_card {
        margin: 0;
        margin-bottom: 12px;
        padding: 24px;
        border-radius: 6px;
        border: 1px solid #E9E9E9;
        background: #FBFBFB;

        .heading {
            color: #7C7C7C;
            font-family: "CircularStd";
            font-size: 18px;
            font-style: normal;
            margin: 0;
            margin-bottom: 8px;
        }

        .mini_box_container {
            .mini_box {
                p {
                    margin: 0;
                    margin-bottom: 12px;
                }

                .card_title {
                    color: #FF7C21;
                    font-family: "CircularStd";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                }

                .link {
                    color: #6D6D6D;
                    font-family: "CircularStd";
                    font-size: 16px;
                }
            }
        }

        .assessment_box_container {
            .assessment_box {
                margin: 0;
                margin-bottom: 12px;

                p {
                    margin: 0;
                    margin-bottom: 12px;
                }

                .card_title {
                    color: #FF7C21;
                    font-family: "CircularStd";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                }

                .question,
                .answer {
                    margin-bottom: 0;

                    span {
                        margin-right: 8px;
                    }
                }

                .question {
                    color: #6D6D6D;
                    font-family: "CircularStd";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 450;
                }

                .answer {
                    color: #494949;
                    font-family: "CircularStd";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                }
            }
        }
    }
}