.orangeCard {
    background-color: #ff7c21;
    color: #fff;
}

.orangeCard h3 {
    color: #fff;
}

.orangeCard:hover {
    background-color: #fff;
    // color:#ff7c21;
}

.orangeCard:hover h3,
.orangeCard:hover span {
    // background-color: #fff;
    color: #ff7c21 !important;
}

.orangeCard {

    .dash-widget-icon {
        background: transparent;
        color: white;
        font-size: 50px;
        border-radius: unset;
        border-right: 1px solid;
        height: 80px;
        padding-right: 12px;
    }

    .dash-widget-info {
        text-align: start;
    }
}

.dc_dashboard_card {
    &:hover {
        .img_icon_outer {
            .imgicon.white {
                display: block !important;
            }

            .imgicon.orange {
                display: none !important;
            }
        }
    }

    .img_icon_outer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .imgicon {
            width: 30px;
            height: 30px;
            position: absolute;

            &.white {
                display: block;
            }

            &.orange {
                display: none;
            }
        }
    }
}